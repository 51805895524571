import styled from 'styled-components';

export const Flex = styled.div`
    display: flex;
`;

export const ContactInputsWrapper = styled.div`
    margin-top: 150px;
    padding-left: 150px;
    padding-right: 150px;
    justify-content: center;
  `;

export const Inputs = styled.div`

`;