import React from 'react';
import { ContactInputsWrapper, Flex, Inputs } from './contactInputs.style';

export default function Projects() {
  return (
    <ContactInputsWrapper>
        <Flex>
            <font style={{fontSize: '30px', fontFamily: 'sans-serif', marginRight: '40%'}}>Get in Touch.</font>
            <Inputs>
                <div>
                    Name
                </div>
                <Flex>
                    <input>
                    
                    </input>
                    <input>
                    
                    </input>
                </Flex>
                <div>
                    Email
                </div>
                <input>
                
                </input>
                <div>
                    Subject
                </div>
                <input>
                
                </input>
                <div>
                    Message
                </div>
                <input>
                
                </input>
                <div>
                    <button>Submit</button>
                </div>
            </Inputs>
        </Flex>
    </ContactInputsWrapper>
  );
}
