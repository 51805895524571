import React from 'react';
import ContactInputs from '../components/App/ContactInputs';
import { Helmet } from 'react-helmet';
import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  }
`;
export default function Page(props) {
  return (
    <div>
      <GlobalStyle />
      <Helmet>
        <meta charSet='utf-8' />
        <meta property='og:title' content='John Morales' />
        <meta name='author' content='John Morales' />
        <title>Contact Me</title>
      </Helmet>
      <ContactInputs />
    </div>
  );
}
